/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// import all image files in a folder:
require.context('../images', true)
require.context('../frontend/base/typo/fonts', true)

import '../frontend/libs/bootstrap/bootstrap.scss'
import '../frontend/libs/bootstrap/bootstrap.js'

import '../frontend/libs/bootstrap-datetimepicker/bootstrap-datetimepicker.scss'
import '../frontend/libs/bootstrap-datetimepicker/bootstrap-datetimepicker.js'

import svg4everybody from 'svg4everybody'

document.addEventListener('turbolinks:load', () => push_and_call((place) => svg4everybody()))

// usercentrics has trouble with turbolinks see refs #74812
document.addEventListener('turbolinks:load', () => {
  if ('undefined' !== typeof window.usercentrics && 'function' === typeof window.usercentrics.initInterface) {
    // we just reinitialize usercentrics, after turbolinks loads a new page
    $('#usercentrics-button').remove();
    window.usercentrics.initInterface();
  }
})

// we trigger events in google tag manager, to know when usercentrics is loaded
function triggerEvent(element, type) {
  var event = null;
  if (typeof window.CustomEvent === "function") {
      event = new CustomEvent(type);
  } else {
      event = document.createEvent('CustomEvent');
      event.initCustomEvent(type, false, true, null);
  }
  element.dispatchEvent(event);
}
window.triggerEvent = triggerEvent;

function createOneTimeListener(element, event, listener) {
  var listener_func;
  listener_func = function() {
    element.removeEventListener(event, listener_func);
    return listener();
  }
  element.addEventListener(event, listener_func);
}
window.createOneTimeListener = createOneTimeListener;
